/* eslint-disable */
import http from '@/service/http';
import { formatDate } from '@/helpers/time.helper';
const DEFAULT_THUMBNAIL = 'https://storage.googleapis.com/itify-customshirt.appspot.com/tshirt/templates/template1.png';
const TSHIRT_CATEGORY_ID = 1

const state = {
  selectedDesign: {},
  selectedTextDesign: {},
  masterDesignTexts: [],
};

const getters = {
  selectedDesign() {
    return state.selectedDesign;
  },
  masterDesignTexts() {
    return state.masterDesignTexts;
  },
};

const mutations = {
  setSelectedDesign(_state, data) {
    state.selectedDesign = data;
  },
  setDesignTexts(_state, data) {
    state.masterDesignTexts = data;
  },
  setSelectedTextDesign(_state, data) {
    state.selectedTextDesign = data;
  },
};

const actions = {
  async getDesigns({ commit }) {
    const url = `designs?category_id=${TSHIRT_CATEGORY_ID}`;
    const result = await http.get(url);
    const designs = mappingDesigns(result.data.data.designs);
    return designs;
  },
  async saveDesign({ commit }, params) {
    return await http.post('designs', params);
  },
  async addProductToCart({ commit }, params) {
    return await http.post('add-to-cart', params);
  },
  async getCartItems({ commit }) {
    const result = await http.get('carts');
    return result.data.data.cartItems;
  },
  async deleteDesign({ commit }, ids) {
    const params = {
      ids: ids
    }
    return await http.post('designs/delete', params);
  },
  async getDetailDesign({ commit }, id) {
    const url = `/designs/${id}`;
    const { data } = await http.get(url);
    if (data.status === 'ok') {
      const design = data.data.design;
      commit('setSelectedDesign', design);

      return design;
    }

    return {};
  },
  async getTextDetailDesign({ commit }, id) {
    const url = `/text_designs/${id}`;
    const { data } = await http.get(url);
    if (data.status === 'ok') {
      const design = data.data.design;
      commit('setSelectedTextDesign', design);

      return design;
    }

    return {};
  },
  /* eslint-disable no-unused-vars */
  async getMasterDesignTexts({ commit }) {
    const url = 'designs/texts';
    const { data } = await http.getWithoutAuthentication(url);
    if (data.status === 'ok') {
      commit('setDesignTexts', data.data.textDesigns);
      return data.data.textDesigns;
    }
    return [];
  },
};

const mappingDesigns = (designs) => {
  const mappedDesigns = []
  for (const design of designs) {
    mappedDesigns.push(mappingDesign(design));
  }

  return mappedDesigns;
}

const mappingDesign = (design) => {
  const mappedDesign = {
    backDesign: design.backDesign,
    frontDesign: design.frontDesign,
    id: design.id,
    masterProductTypeId: design.masterProductTypeId,
    productColor: design.productColor,
    productDescription: design.productDescription,
    productName: design.productName,
    productSize: design.productSize,
    thumbnail: design.thumbnail || DEFAULT_THUMBNAIL,
    createdAt: formatDate(design.createdAt),
    updatedAt: formatDate(design.updatedAt),
  }

  return mappedDesign;
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
