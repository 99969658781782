import http from '@/service/http';

const state = {
  masterTypeList: [],
  masterTypeSelected: {},
};

const getters = {
  masterTypeList() {
    return state.masterTypeList;
  },
  masterTypeSelected() {
    return state.masterTypeSelected;
  },
};

const mutations = {
  setMasterTypes(_state, data) {
    state.masterTypeList = data;
  },
  setMasterTypeSelected(_state, data) {
    state.masterTypeSelected = data;
  },
};

const actions = {
  /* eslint-disable no-unused-vars */
  async getMasterTypes({ commit }) {
    const url = 'types';
    const { data } = await http.getWithoutAuthentication(url);
    if (data.status === 'ok') {
      commit('setMasterTypes', data.data.types);
      return data.data.types;
    }
    return [];
  },
  setMasterTypeSelected({ commit }, { masterType }) {
    commit('setMasterTypeSelected', masterType);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
