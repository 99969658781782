/* eslint-disable */
import Vue from 'vue';
// import vuetify from '@/plugins/vuetify'; // path to vuetify export
import VueI18n from 'vue-i18n';
import { ColorPicker, ColorPanel } from 'one-colorpicker';
import axios from 'axios';
// import loader from "vue-ui-preloader";
import Fragment from 'vue-fragment';
import JwPagination from 'jw-vue-pagination';
import App from './App.vue';
import router from './router';
import store from './store';
// import 'vuetify/dist/vuetify.min.css';
import vnMessage from './lang/vn.json';
import enMessage from './lang/en.json';
import VueLazyload from 'vue-lazyload';
import loader from "vue-ui-preloader";
import Skeleton from 'vue-loading-skeleton';
import "vue-loading-skeleton/dist/style.css"

// require('./assets/css/custome.css');
// require('./assets/css/flex-slider.css');
// require('./assets/css/fontawesome.css');
// require('./assets/css/owl.css');
require('./assets/css/style.min.css');
require('./assets/css/fonts.css');
require('./assets/css/custom.css');
// require('./assets/js/jquery.min.js');
// require('./assets/css/bootstrap.min.css');
// require('./assets/js/bootstrap.bundle.min.js');
// require('./assets/js/owl.js');
// require('./assets/js/slick.js');
// require('./assets/js/accordions.js');
// require('./assets/js/isotope.js');
require('./assets/js/custom.js');

Vue.prototype.$http = axios;
Vue.prototype.$http.defaults.headers.common = {
  'Authorization': 'Bearer ' + localStorage.getItem('token')
};
Vue.config.productionTip = false;
Vue.use(VueI18n);
Vue.use(ColorPanel);
Vue.use(ColorPicker);
Vue.use(loader);
Vue.use(Fragment.Plugin);
Vue.use(VueLazyload);
Vue.use(Skeleton)
Vue.component('jw-pagination', JwPagination);

const messages = {
  vn: vnMessage,
  en: enMessage,
};
const i18n = new VueI18n({
  locale: 'vn', // set locale
  messages,
  fallbackLocale: 'vn',
});

new Vue({
  router,
  store,
  i18n,
  components:{
    loader:loader
  },
  render: (h) => h(App),
}).$mount('#app');
