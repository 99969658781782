<template>
  <!-- <div id="app" class="" v-responsive="{ small: el => el.width <= 500 }"> -->
    <!-- <webapp-preload/> -->
  <fragment id="app">
    <webapp-header>
    </webapp-header>
    <webapp-content/>
    <webapp-footer v-if="!isDesign"/>
  </fragment>
</template>

<script>
/* eslint-disable */
import { ResponsiveDirective } from 'vue-responsive-components';
// import preload from './views/PreLoad.vue';
import header from './views/Header.vue';
import content from './views/Content.vue';
import footer from './views/Footer.vue';

export default {
  name: 'App',
  props: ['post'],
  components: {
    // 'webapp-preload': preload,
    'webapp-header': header,
    'webapp-content': content,
    'webapp-footer': footer,
  },
  computed: {
  isDesign() {
    return this.$route.path.includes('/design');
  }
  
},
  directives: {
    responsive: ResponsiveDirective,
  },
};
</script>
