import http from '@/service/http';
import { removeImagesFromStorage } from '@/helpers/storage.helper';

const state = {
  currentUser: {},
};

const getters = {
  currentUser() {
    if (!state.currentUser.id) {
      const currentUser = {
        id: localStorage.getItem('id'),
        name: localStorage.getItem('name'),
        email: localStorage.getItem('email'),
        role: localStorage.getItem('role'),
      };
      state.currentUser = currentUser;
    }
    return state.currentUser;
  },
};

const mutations = {
  setCurrentUser(_state, data) {
    state.currentUser = data;
  },
};

const actions = {
  authenticateWithServer({ commit }, params) {
    http.postWithoutAuthentication('/authentication/login', params).then((result) => {
      const currentUser = {
        id: result.data.data.account.id,
        name: result.data.data.account.displayName,
        email: result.data.data.account.email,
        role: result.data.data.account.role,
      };
      commit('setCurrentUser', currentUser);
      localStorage.setItem('token', result.data.data.token);
      localStorage.setItem('id', result.data.data.account.id);
      localStorage.setItem('name', result.data.data.account.displayName);
      localStorage.setItem('email', result.data.data.account.email);
      localStorage.setItem('role', result.data.data.account.role);
      return result.data.data;
    }).catch((err) => {
      console.log(err.response);
    });
  },
  setCurrentUser({ commit }, { currentUser }) {
    commit('setCurrentUser', currentUser);
  },
  logout({ commit }) {
    localStorage.removeItem('token');
    localStorage.removeItem('id');
    localStorage.removeItem('name');
    localStorage.removeItem('email');
    localStorage.removeItem('role');
    removeImagesFromStorage();
    commit('setCurrentUser', {});
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
