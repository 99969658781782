import axios from 'axios';

const API_URL = process.env.VUE_APP_API_BASE_URL;

/* eslint-disable no-unused-vars */
const handleOkResponse = (response, resolve, message) => {
  resolve(response);
};

const handleErrorResponse = (error, reject) => {
  reject(error);
};

const service = {
  getWithoutAuthentication(endPoint, message) {
    return new Promise((resolve, reject) => {
      axios.get(`${API_URL}${endPoint}`, {})
        .then((response) => {
          handleOkResponse(response, resolve, message);
        })
        .catch((error) => {
          handleErrorResponse(error, reject);
        });
    });
  },
  get(endPoint, message) {
    return new Promise((resolve, reject) => {
      axios.get(`${API_URL}${endPoint}`, { headers: { Authorization: `bearer ${localStorage.getItem('token')}` } })
        .then((response) => {
          handleOkResponse(response, resolve, message);
        })
        .catch((error) => {
          handleErrorResponse(error, reject);
        });
    });
  },
  postWithoutAuthentication(endPoint, params, message) {
    return new Promise((resolve, reject) => {
      axios.post(`${API_URL}${endPoint}`, params, {})
        .then((response) => {
          handleOkResponse(response, resolve, message);
        })
        .catch((error) => {
          handleErrorResponse(error, reject);
        });
    });
  },
  post(endPoint, params, message) {
    return new Promise((resolve, reject) => {
      axios.post(`${API_URL}${endPoint}`, params, { headers: { Authorization: `bearer ${localStorage.getItem('token')}` } })
        .then((response) => {
          handleOkResponse(response, resolve, message);
        })
        .catch((error) => {
          handleErrorResponse(error, reject);
        });
    });
  },
  delete(endPoint, message) {
    return new Promise((resolve, reject) => {
      axios.delete(`${API_URL}${endPoint}`, { headers: { Authorization: `bearer ${localStorage.getItem('token')}` } })
        .then((response) => {
          handleOkResponse(response, resolve, message);
        })
        .catch((error) => {
          handleErrorResponse(error, reject);
        });
    });
  },
};
export default service;
