/* eslint-disable */
import http from '@/service/http';

const actions = {
  async getListOrder({ commit }) {
    const result = await http.get('orders');
    return {orderList: result.data.data.orderList, cartItems: result.data.data.cartItems };
  }
};

export default {
  namespaced: true,
  actions,
};
