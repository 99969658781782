import http from '@/service/http';

const state = {
  previewImages: [],
};

const getters = {
  previewImages() {
    return state.previewImages;
  },
};

const mutations = {
  setPreviewImages(_state, data) {
    state.previewImages = data;
  },
};

const actions = {
  async getImagePreviews({ commit }, params) {
    const { data } = await http.post('imagePreviews', params);
    if (data.status === 'ok') {
      commit('setPreviewImages', data.data.previewImages);
      return data.data.previewImages;
    }
    return [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
