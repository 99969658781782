import http from '@/service/http';

const state = {
  collectionList: [],
};

const getters = {
  collectionList() {
    return state.collectionList;
  },
};

const mutations = {
  setCollectionByCategory(_state, data) {
    state.collectionList = data;
  },
};

const actions = {
  /* eslint-disable no-unused-vars */
  async getCollectionByCategory({ commit }) {
    const url = 'collections?category_id=1';
    const { data } = await http.getWithoutAuthentication(url);
    if (data.status === 'ok') {
      commit('setCollectionByCategory', data.data.collections);
      return data.data.collections;
    }
    return [];
  },
  async getCollections({ commit }) {
    const url = 'collectionsWithoutProduct';
    const { data } = await http.getWithoutAuthentication(url);
    if (data.status === 'ok') {
      commit('setCollectionByCategory', data.data.collections);
      return data.data.collections;
    }
    return [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
