<template>
  <!-- eslint-disable -->
  <!-- start header.html-->
<header class="header">
  <nav class="navbar navbar-expand-xl bg-light">
    <div class="container">
      <a class="navbar-brand" href="/">
        <img src="../assets/images/logo.png" class="d-none d-lg-block" alt="" />
        <img src="../assets/images/logo-mobile.png" class="d-block d-lg-none" alt="" />
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <div class="collapse__tool d-flex d-xl-none">
          <div class="collapse__tool--logo col-10">
            <a href="/">
              <img src="../assets/images/logo.png" alt="" class="m-0">
            </a>
          </div>
          <button class="btn btn-close-collapse p-0 col-2" @click="closeHambuger">
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="8.68652" y="7.44885" width="34" height="1.5" rx="0.75" transform="rotate(45 8.68652 7.44885)"
                fill="#656782" />
              <rect x="7.27246" y="31.4905" width="34" height="1.5" rx="0.75" transform="rotate(-45 7.27246 31.4905)"
                fill="#656782" />
            </svg>
          </button>
        </div>
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li class="nav-item" @click="closeMenu">
            <router-link class="nav-link" aria-current="page" :class="{ active: isActiveDesign }" :to="{ name: 'NewDesign'}">
              Design
            </router-link>
          </li>
          <li class="nav-item" @click="closeMenu">
            <router-link class="nav-link" aria-current="page" :class="{ active: isActiveTemplate }" :to="{ name: 'Template'}">
              Collection
            </router-link>
          </li>
          <!-- <li class="nav-item" @click="closeMenu">
            <router-link class="nav-link" aria-current="page" :class="isActiveShop ? 'active' : ''" :to="{ name: 'Shop'}">
              Shop
            </router-link>
          </li> -->
          <!-- <li class="nav-item" @click="closeMenu">
            <router-link class="nav-link" aria-current="page" :class="isActiveContact ? 'active' : ''" :to="{ name: 'Contact'}">
              Contact
            </router-link>
          </li> -->
          <li class="nav-item" @click="closeMenu">
            <router-link class="nav-link" aria-current="page" :class="isActiveAbout ? 'active' : ''" :to="{ name: 'About'}">
              About us
            </router-link>
          </li>
          <li class="nav-item cart d-none d-xl-block" v-if="currentUser.id">
            <a class="nav-link position-relative" href="/cart">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd"
                  d="M15.75 6.51011C15.75 4.31676 13.8655 2.55996 11.6324 2.77661L11.6297 2.77687C9.80824 2.94687 8.25 4.79256 8.25 6.70011V7.67011C8.25 8.08433 7.91421 8.42011 7.5 8.42011C7.08579 8.42011 6.75 8.08433 6.75 7.67011V6.70011C6.75 4.10811 8.81106 1.53423 11.4889 1.28348C14.6152 0.98098 17.25 3.44391 17.25 6.51011V7.89011C17.25 8.30433 16.9142 8.64011 16.5 8.64011C16.0858 8.64011 15.75 8.30433 15.75 7.89011V6.51011Z"
                  fill="#656782" />
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M3.37437 8.81723C4.25978 7.76457 5.77779 7.25 8.00083 7.25H16.0008C18.2239 7.25 19.7419 7.76457 20.6273 8.81723C21.5056 9.8614 21.5878 11.2337 21.4463 12.5125L21.4451 12.523L20.6958 18.5167C20.5853 19.5429 20.3264 20.6669 19.4104 21.5037C18.4993 22.3361 17.0877 22.75 15.0008 22.75H9.00083C6.91398 22.75 5.50236 22.3361 4.59121 21.5037C3.67527 20.6669 3.41637 19.5429 3.30581 18.5166L2.5553 12.5125C2.4138 11.2337 2.49609 9.8614 3.37437 8.81723ZM4.04571 12.3423L4.79668 18.3501C4.89652 19.282 5.10338 19.9399 5.60294 20.3963C6.1093 20.8589 7.06768 21.25 9.00083 21.25H15.0008C16.934 21.25 17.8924 20.8589 18.3987 20.3963C18.8983 19.9399 19.1052 19.282 19.2051 18.3501L19.2065 18.337L19.956 12.3422C20.0835 11.1837 19.9504 10.3428 19.4794 9.78277C19.0148 9.23043 18.0478 8.75 16.0008 8.75H8.00083C5.95386 8.75 4.98688 9.23043 4.52229 9.78277C4.05126 10.3428 3.91818 11.1838 4.04571 12.3423Z"
                  fill="#656782" />
                <path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd"
                  d="M14.4961 12C14.4961 11.4477 14.9438 11 15.4961 11H15.5051C16.0574 11 16.5051 11.4477 16.5051 12C16.5051 12.5523 16.0574 13 15.5051 13H15.4961C14.9438 13 14.4961 12.5523 14.4961 12Z"
                  fill="#656782" />
                <path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd"
                  d="M7.49609 12C7.49609 11.4477 7.94381 11 8.49609 11H8.50508C9.05736 11 9.50508 11.4477 9.50508 12C9.50508 12.5523 9.05736 13 8.50508 13H8.49609C7.94381 13 7.49609 12.5523 7.49609 12Z"
                  fill="#656782" />
              </svg>
            </a>
            <div class="cart__item" id="cart_item_data">{{ cartNumber }}</div>
          </li>
          <li class="nav-item" v-if="!currentUser.id">
            <a class="nav-link" :class="{ active: isActiveLogin }" aria-current="page" href="/login">Login</a>
          </li>
          <li class="nav-item user dropdown d-none d-xl-block" v-if="currentUser.id">
            <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M12 3.00342C9.65279 3.00342 7.75 4.90621 7.75 7.25342C7.75 9.60063 9.65279 11.5034 12 11.5034C14.3472 11.5034 16.25 9.60063 16.25 7.25342C16.25 4.90621 14.3472 3.00342 12 3.00342ZM6.25 7.25342C6.25 4.07778 8.82436 1.50342 12 1.50342C15.1756 1.50342 17.75 4.07778 17.75 7.25342C17.75 10.429 15.1756 13.0034 12 13.0034C8.82436 13.0034 6.25 10.429 6.25 7.25342Z"
                  fill="#656782" />
                <path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd"
                  d="M2.66016 21.75C2.66016 17.3291 6.99728 14 12.0002 14C17.003 14 21.3401 17.3291 21.3401 21.75C21.3401 22.1642 21.0044 22.5 20.5901 22.5C20.1759 22.5 19.8401 22.1642 19.8401 21.75C19.8401 18.4309 16.4773 15.5 12.0002 15.5C7.52303 15.5 4.16016 18.4309 4.16016 21.75C4.16016 22.1642 3.82437 22.5 3.41016 22.5C2.99594 22.5 2.66016 22.1642 2.66016 21.75Z"
                  fill="#656782" />
              </svg>
            </a>
            <ul class="dropdown-menu">
              <!-- <router-link class="dropdown-item" aria-current="page" :to="{ name: 'Profile'}">
                Profile
              </router-link> -->
              <li><router-link to="/my_design" class="dropdown-item">My Design</router-link></li>
              <li><router-link to="/wishlist" class="dropdown-item">Wishlist</router-link></li>
              <!-- <li><router-link to="/order-history" class="dropdown-item">Order History</router-link></li> -->
              <li><router-link to="/order-history" class="dropdown-item">My Order</router-link></li>
              <li><a class="dropdown-item" @click="logoutHandler">Logout</a></li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="cart-user-item d-block d-xl-none">

        <ul class="navbar-nav ms-auto mb-2 mb-lg-0 flex-row">
          <li class="nav-item cart" v-if="currentUser.id">
            <a class="nav-link position-relative" href="/cart">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd"
                  d="M15.75 6.51011C15.75 4.31676 13.8655 2.55996 11.6324 2.77661L11.6297 2.77687C9.80824 2.94687 8.25 4.79256 8.25 6.70011V7.67011C8.25 8.08433 7.91421 8.42011 7.5 8.42011C7.08579 8.42011 6.75 8.08433 6.75 7.67011V6.70011C6.75 4.10811 8.81106 1.53423 11.4889 1.28348C14.6152 0.98098 17.25 3.44391 17.25 6.51011V7.89011C17.25 8.30433 16.9142 8.64011 16.5 8.64011C16.0858 8.64011 15.75 8.30433 15.75 7.89011V6.51011Z"
                  fill="#656782" />
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M3.37437 8.81723C4.25978 7.76457 5.77779 7.25 8.00083 7.25H16.0008C18.2239 7.25 19.7419 7.76457 20.6273 8.81723C21.5056 9.8614 21.5878 11.2337 21.4463 12.5125L21.4451 12.523L20.6958 18.5167C20.5853 19.5429 20.3264 20.6669 19.4104 21.5037C18.4993 22.3361 17.0877 22.75 15.0008 22.75H9.00083C6.91398 22.75 5.50236 22.3361 4.59121 21.5037C3.67527 20.6669 3.41637 19.5429 3.30581 18.5166L2.5553 12.5125C2.4138 11.2337 2.49609 9.8614 3.37437 8.81723ZM4.04571 12.3423L4.79668 18.3501C4.89652 19.282 5.10338 19.9399 5.60294 20.3963C6.1093 20.8589 7.06768 21.25 9.00083 21.25H15.0008C16.934 21.25 17.8924 20.8589 18.3987 20.3963C18.8983 19.9399 19.1052 19.282 19.2051 18.3501L19.2065 18.337L19.956 12.3422C20.0835 11.1837 19.9504 10.3428 19.4794 9.78277C19.0148 9.23043 18.0478 8.75 16.0008 8.75H8.00083C5.95386 8.75 4.98688 9.23043 4.52229 9.78277C4.05126 10.3428 3.91818 11.1838 4.04571 12.3423Z"
                  fill="#656782" />
                <path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd"
                  d="M14.4961 12C14.4961 11.4477 14.9438 11 15.4961 11H15.5051C16.0574 11 16.5051 11.4477 16.5051 12C16.5051 12.5523 16.0574 13 15.5051 13H15.4961C14.9438 13 14.4961 12.5523 14.4961 12Z"
                  fill="#656782" />
                <path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd"
                  d="M7.49609 12C7.49609 11.4477 7.94381 11 8.49609 11H8.50508C9.05736 11 9.50508 11.4477 9.50508 12C9.50508 12.5523 9.05736 13 8.50508 13H8.49609C7.94381 13 7.49609 12.5523 7.49609 12Z"
                  fill="#656782" />
              </svg>
            </a>
            <div class="cart__item">{{ cartNumber }}</div>
          </li>
          <li class="nav-item user dropdown" v-if="currentUser.id">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M12 3.00342C9.65279 3.00342 7.75 4.90621 7.75 7.25342C7.75 9.60063 9.65279 11.5034 12 11.5034C14.3472 11.5034 16.25 9.60063 16.25 7.25342C16.25 4.90621 14.3472 3.00342 12 3.00342ZM6.25 7.25342C6.25 4.07778 8.82436 1.50342 12 1.50342C15.1756 1.50342 17.75 4.07778 17.75 7.25342C17.75 10.429 15.1756 13.0034 12 13.0034C8.82436 13.0034 6.25 10.429 6.25 7.25342Z"
                  fill="#656782" />
                <path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd"
                  d="M2.66016 21.75C2.66016 17.3291 6.99728 14 12.0002 14C17.003 14 21.3401 17.3291 21.3401 21.75C21.3401 22.1642 21.0044 22.5 20.5901 22.5C20.1759 22.5 19.8401 22.1642 19.8401 21.75C19.8401 18.4309 16.4773 15.5 12.0002 15.5C7.52303 15.5 4.16016 18.4309 4.16016 21.75C4.16016 22.1642 3.82437 22.5 3.41016 22.5C2.99594 22.5 2.66016 22.1642 2.66016 21.75Z"
                  fill="#656782" />
              </svg>
            </a>
            <ul class="dropdown-menu">
              <div class="collapse__tool justify-content-end">
                <button class="btn btn-close-collapse p-0">
                  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="8.68652" y="7.44885" width="34" height="1.5" rx="0.75" transform="rotate(45 8.68652 7.44885)"
                      fill="#656782" />
                    <rect x="7.27246" y="31.4905" width="34" height="1.5" rx="0.75" transform="rotate(-45 7.27246 31.4905)"
                      fill="#656782" />
                  </svg>
                </button>
              </div>
              <!-- <li><router-link to="/profile" class="dropdown-item">Profile</router-link></li> -->
              <li><router-link to="/my_design" class="dropdown-item">My Design</router-link></li>
              <li><router-link to="/wishlist" class="dropdown-item">Wishlist</router-link></li>
              <li><router-link to="/order-history" class="dropdown-item">My Order</router-link></li>
              <!-- <li><router-link to="/order-history" class="dropdown-item">Order History</router-link></li> -->
              <li><a class="dropdown-item" href @click="logoutHandler">Logout</a></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>
<!-- end header.html-->
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

/* eslint-disable */
export default {
  data() {
    return {
      cartNumber: 0,
    }
  },
  async mounted() {
    if (this.currentUser) { this.cartNumber = await this.getCartNumbers(); }
  },
  methods: {
    ...mapActions({
      logout: 'authentication/logout',
      getCartNumbers: 'cart/getCartNumbers',
    }),
    async logoutHandler() {
      await this.logout();
      this.$router.push('/');
    },
    closeMenu(){
      $('body').removeClass('menu-is-open');
      $('.navbar-collapse').removeClass('show');
    },
    closeHambuger(){
      $('body').removeClass('menu-is-open');
      $('.navbar-collapse').removeClass('show');
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'authentication/currentUser',
    }),
    isActiveDesign() {
      return this.$route.name === "NewDesign" || this.$route.name === "EditDesign" || window.location.href.indexOf("/design") > -1;
    },
    isActiveTemplate() {
      return this.$route.name === "Home" || this.$route.name === "Template";
    },
    isActiveShop() {
      return this.$route.name === "Shop";
    },
    isActiveAbout() {
      return this.$route.name === "About";
    },
    isActiveContact() {
      return this.$route.name === "Contact";
    },
    isActiveLogin() {
      return this.$route.name === "Login";
    }
  }
}
</script>
