import http from '@/service/http';

const state = {
  templates: [],
  popularTemplates: [],
  trendingTemplates: [],
  template: {},
  alsoLikeTemplates: [],
};

const getters = {
  templates() {
    return state.templates;
  },
  popularTemplates() {
    return state.popularTemplates;
  },
  trendingTemplates() {
    return state.trendingTemplates;
  },
  template() {
    return state.template;
  },
  alsoLikeTemplates() {
    return state.alsoLikeTemplates;
  },
};

const mutations = {
  setTemplates(_state, data) {
    state.templates = data;
  },
  setPopularTemplates(_state, data) {
    state.popularTemplates = data;
  },
  setTrendingTemplates(_state, data) {
    state.trendingTemplates = data;
  },
  setTemplate(_state, data) {
    state.template = data;
  },
  setAlsoLikeTemplates(_state, data) {
    state.alsoLikeTemplates = data;
  },
};

const actions = {
  async getTemplates({ commit }) {
    const url = 'templates';
    const { data } = await http.get(url);
    if (data.status === 'ok') {
      commit('setTemplates', data.data.templates);
      return data.data.templates;
    }
    return [];
  },
  async getPopularTemplates({ commit }) {
    const url = 'templates/popular';
    const { data } = await http.get(url);
    if (data.status === 'ok') {
      commit('setPopularTemplates', data.data.templates);
      return data.data.templates;
    }
    return [];
  },
  async getTrendingTemplates({ commit }) {
    const url = 'templates/trending';
    const { data } = await http.get(url);
    if (data.status === 'ok') {
      commit('setTrendingTemplates', data.data.templates);
      return data.data.templates;
    }
    return [];
  },
  async getTemplateById({ commit }, id) {
    const url = `templates/${id}`;
    const { data } = await http.get(url);
    if (data.status === 'ok') {
      commit('setTemplate', data.data.template);
      return data.data.template;
    }
    return [];
  },
  async getAlsoLikeTemplates({ commit }, { id, typeId }) {
    const url = `templates/recomended?type_id=${typeId}&id=${id}`;
    const { data } = await http.get(url);
    if (data.status === 'ok') {
      commit('setAlsoLikeTemplates', data.data.templates);
      return data.data.templates;
    }
    return [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
