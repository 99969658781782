import http from '@/service/http';

const state = {
  wishlists: [],
  wishlistTemplateIds: [],
};

const getters = {
  wishlists() {
    return state.wishlists;
  },
  wishlistTemplateIds() {
    return state.wishlistTemplateIds;
  },
};

const mutations = {
  setWishlists(_state, data) {
    state.wishlists = data;
    state.wishlistTemplateIds = data.map((wishlist) => wishlist.productId);
  },
  addToWishlists(_state, data) {
    const templateId = data.productId;
    if (!state.wishlistTemplateIds.includes(templateId)) {
      state.wishlistTemplateIds.push(templateId);
      state.wishlists.push(data);
    }
  },
  removeWishlist(_state, data) {
    state.wishlists = state.wishlists.filter((wishlist) => wishlist.productId !== data.productId);
    state.wishlistTemplateIds = state.wishlistTemplateIds.filter((id) => id !== data.productId);
  },
};

const actions = {
  /* eslint-disable no-unused-vars */
  async getWishlists({ commit }) {
    const url = 'wishlists?category_id=1';
    const { data } = await http.get(url);
    if (data.status === 'ok') {
      commit('setWishlists', data.data.wishlists);
      return data.data.wishlists;
    }
    return [];
  },
  async addWishlist({ commit }, params) {
    const url = 'wishlist';
    const { data } = await http.post(url, params);
    if (data.status === 'ok') {
      commit('addToWishlists', data.data.wishlist);
      return data.data.wishlist;
    }
    return [];
  },
  async removeWishlist({ commit }, params) {
    const url = 'wishlist/delete';
    const { data } = await http.post(url, params);
    if (data.status === 'ok') {
      commit('removeWishlist', data.data.wishlist);
      return data.data.wishlist;
    }
    return [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
