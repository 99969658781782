import Vue from 'vue';
import Vuex from 'vuex';
import masterTypes from './masterType';
import authentication from './authentication';
import design from './design';
import masterShapes from './masterShape';
import collections from './collection';
import preview from './preview';
import wishlist from './wishlist';
import template from './template';
import cart from './cart';
import order from './order';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    masterTypes,
    authentication,
    design,
    masterShapes,
    collections,
    preview,
    wishlist,
    template,
    cart,
    order,
  },
});
