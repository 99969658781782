export const MAX_ITEMS = 10;

/* eslint-disable */
export const getImagesFromStorage = () => {
  const images = [];
  for (let index = 0; index < MAX_ITEMS; index++) {
    const imageStorage = localStorage.getItem(`image${index}`);
    if (imageStorage) {
      const image = JSON.parse(imageStorage);
      images[index] = image;
    }
  }
  return images;
};

export const removeImagesFromStorage = () => {
  for (let index = 0; index < MAX_ITEMS; index++) {
    localStorage.removeItem(`image${index}`);
  }
};

export const oldestImage = (images) => {
  let min = Infinity;
  let index;

  images.forEach((v, idx) => {
    if (min > v.createdAt) {
      min = v.createdAt;
      index = idx; 
    }
  });

  return index;
};

export const saveImageBase64ToStorage = (imageObject) => {
  const images = getImagesFromStorage();
  let key;

  if (images.length === 0) {
    key = 'image0';
    localStorage.setItem(key, JSON.stringify(imageObject));
  } else if (images.length > 0 && images.length < MAX_ITEMS) {
    key = `image${images.length}`;
    localStorage.setItem(key, JSON.stringify(imageObject));
  } else {
    const indexOfOldestImage = oldestImage(images);
    key = `image${indexOfOldestImage}`;
    localStorage.setItem(key, JSON.stringify(imageObject));
  }
};
