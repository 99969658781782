import http from '@/service/http';

const state = {
  masterShapes: [],
};

const getters = {
  masterShapes() {
    return state.masterShapes;
  },
};

const mutations = {
  setMasterShape(_state, data) {
    state.masterShapes = data;
  },
};

const actions = {
  /* eslint-disable no-unused-vars */
  async getMasterShapes({ commit }) {
    const url = 'designs/shapes';
    const { data } = await http.getWithoutAuthentication(url);
    if (data.status === 'ok') {
      commit('setMasterShape', data.data.shapes);
      return data.data.shapes;
    }
    return [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
