/* eslint-disable */
import http from '@/service/http';

const actions = {
  async checkout({ commit }, params) {
    const result = await http.post('pay', params);
    return result.data.data.link;
  },
  async processPayment({ commit }, params) {
    return await http.post('process-payment', params);
  },
  async addProductToCart({ commit }, params) {
    return await http.post('add-to-cart', params);
  },
  async getCartItems({ commit }) {
    const result = await http.get('carts');
    return result.data.data.cartItems;
  },
  async getCartNumbers({ commit }) {
    const result = await http.get('cart-numbers');
    return result.data.data.cartNumbers;
  },
  async deleteCart({ commit }, params) {
    return await http.post('delete-cart', params);
  },
  async getShippingInformations({ commit }) {
    const result = await http.get('shipping-informations');
    return result.data.data.shippingInformations;
  },
};

export default {
  namespaced: true,
  actions,
};
