import Vue from 'vue';
import VueRouter from 'vue-router';
import axios from 'axios';
import userMixin from '@/mixins/users';
// import Home from '../views/Home.vue';
// import NewDesign from '../views/NewDesign.vue';
// import EditDesign from '../views/EditDesign.vue';
// import Login from '../views/Login.vue';
// import Template from '../views/Template.vue';
// import MyDesign from '../views/MyDesign.vue';
// import Shop from '../views/Shop.vue';
// import ShopDetail from '../views/ShopDetail.vue';
// import Contact from '../views/Contact.vue';
// import Cart from '../views/Cart.vue';
// import CartCheckout from '../views/CartCheckout.vue';
// import CartPayment from '../views/CartPayment.vue';
// import OrderHistory from '../views/OrderHistory.vue';
// import Wishlist from '../views/Wishlist.vue';
// import About from '../views/About.vue';
// import Profile from '../views/Profile.vue';
// import BlendMode from '../views/BlendMode.vue';
// import Success from '../views/Success.vue';
// import Cancel from '../views/Cancel.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "default" */ '../views/Template.vue'),
  },
  {
    path: '/design/:id',
    name: 'EditDesign',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "edit-design" */ '../views/EditDesign.vue'),
  },
  {
    path: '/design',
    name: 'NewDesign',
    component: () => import(/* webpackChunkName: "new-design" */ '../views/NewDesign.vue'),
  },
  {
    path: '/text_design',
    name: 'NewTextDesign',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "new-text-design" */ '../views/NewTextDesign.vue'),
  },
  {
    path: '/text_design/:id',
    name: 'EditTextDesign',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "edit-text-design" */ '../views/EditTextDesign.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "default" */ '../views/Login.vue'),
  },
  {
    path: '/collection',
    name: 'Template',
    component: () => import(/* webpackChunkName: "template" */ '../views/Template.vue'),
  },
  {
    path: '/my_design',
    name: 'MyDesign',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "my-design" */ '../views/MyDesign.vue'),
  },
  {
    path: '/shop',
    name: 'Shop',
    component: () => import(/* webpackChunkName: "shop" */ '../views/Shop.vue'),
  },
  {
    path: '/shop-detail/:id',
    name: 'ShopDetail',
    component: () => import(/* webpackChunkName: "shop" */ '../views/ShopDetail.vue'),
  },
  // {
  //   path: '/contact',
  //   name: 'Contact',
  //   component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue'),
  // },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/cart',
    name: 'Cart',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "cart" */ '../views/Cart.vue'),
  },
  {
    path: '/success',
    name: 'Success',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "success" */ '../views/CartOrderSuccess.vue'),
  },
  {
    path: '/cancel',
    name: 'Cancel',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "cancel" */ '../views/CartOrderFailse.vue'),
  },
  {
    path: '/cart-checkout',
    name: 'CartCheckout',
    component: () => import(/* webpackChunkName: "cart" */ '../views/CartCheckout.vue'),
  },
  {
    path: '/cart-payment',
    name: 'CartPayment',
    component: () => import(/* webpackChunkName: "cart" */ '../views/CartPayment.vue'),
  },
  {
    path: '/order-history',
    name: 'OrderHistory',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "order-history" */ '../views/OrderHistory.vue'),
  },
  {
    path: '/wishlist',
    name: 'Wishlist',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "wishlist" */ '../views/Wishlist.vue'),
  },
  // {
  //   path: '/profile',
  //   name: 'Profile',
  //   meta: { requiresAuth: true },
  //   component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
  // },
  {
    path: '/policy',
    name: 'Policy',
    component: () => import(/* webpackChunkName: "policy" */ '../views/Policy.vue'),
  },
  {
    path: '/terms-of-service',
    name: 'TermsOfService',
    component: () => import(/* webpackChunkName: "terms-of-service" */ '../views/TermsOfService.vue'),
  },
  {
    path: '/*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "not-found" */ '../views/NotFound.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

axios.interceptors.response.use((response) => response,
  (error) => {
    console.log(error);
    // if (error.response.data.error === 'token expired') {
    //   localStorage.removeItem('token');
    //   localStorage.removeItem('expireAt');
    //   toastMessage.showToast(
    //     TOAST_CONSTANT.TOAST_TOKEN_EXPIRED_MESSAGE,
    //     TOAST_CONSTANT.TOAST_ERROR_TYPE,
    //   );
    //   return router.push({ path: '/login' });
    // }
    // if (error.response.data.error === 'invalid token') {
    //   localStorage.removeItem('token');
    //   localStorage.removeItem('expireAt');
    //   return router.push({ path: '/login' });
    // }
    // if (error.response.data.errors
    //     && error.response.data.errors.length > 0
    //     && error.response.data.errors[0].message === 'the resource not found') {
    //   return router.push({ path: '/*' });
    // }
    // if (error.response.data.errors
    //     && error.response.data.errors.length > 0
    //     && error.response.data.errors[0].msg === 'brand_id must be a number') {
    //   return router.push({ path: '/*' });
    // }
    return Promise.reject(error);
  });

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  let user = {};
  let isExpired = false;
  const token = localStorage.getItem('token');
  if (token) {
    user = userMixin.parseToken(token);
    if (!user) {
      localStorage.removeItem('token');
      localStorage.removeItem('email');
      localStorage.removeItem('id');
      localStorage.removeItem('name');
      localStorage.removeItem('role');
    } else if (user && user.exp < Math.floor(new Date().getTime() / 1000)) {
      // Handle expireAt
      isExpired = true;
      localStorage.removeItem('token');
      localStorage.removeItem('email');
      localStorage.removeItem('id');
      localStorage.removeItem('name');
      localStorage.removeItem('role');
    }
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem('token') === null || isExpired) {
      localStorage.setItem('redirectPath', to.path);
      next({ path: '/login' });
    }
    next();
  } else {
    const redirectPath = localStorage.getItem('redirectPath');
    if (redirectPath !== null && token) {
      window.location.href = process.env.VUE_APP_BASE_URL + redirectPath;
      localStorage.removeItem('redirectPath');
    } else {
      next();
    }
  }
});

export default router;
